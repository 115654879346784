import React, {lazy, Suspense} from 'react';
import {Provider} from 'react-redux';
import store from './redux/store';
import {BrowserRouter as Router} from 'react-router-dom';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import {IS_TEAM, PUBLIC_URL, THEME_CONFIG} from './configs/AppConfig';
import Views from './views';

const themes = {
    dark: `${PUBLIC_URL}/css/dark-theme.css`,
    light: `${PUBLIC_URL}/css/light-theme.css`,
};

const AppTeam = lazy(() => import('./team/AppTeam')); // This file should export all Teams related components

function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <ThemeSwitcherProvider
                    themeMap={themes}
                    defaultTheme={THEME_CONFIG.currentTheme}
                    insertionPoint="styles-insertion-point"
                >
                    {
                        IS_TEAM ? (
                            <Suspense fallback={<div>Loading Teams Components...</div>}>
                                <AppTeam />
                            </Suspense>
                        ) : (
                            <Router>
                                <Views />
                            </Router>
                        )
                    }
                </ThemeSwitcherProvider>
            </Provider>
        </div>
    );
}

export default App;
